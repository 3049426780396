import React from 'react';
import { graphql } from 'gatsby';
import _ from 'lodash';

import LocationPageDefault from '@templates/location-page-template-default';

export const query = graphql`
  query($slug: String!) {
    page: sanityLocation(pageInfo: { slug: { current: { eq: $slug } } }) {
      address
      city
      hours
      id
      locationId
      gallery {
        images {
          _key
          caption
          alt
          asset {
            fluid(maxWidth: 1600) {
              ...GatsbySanityImageFluid
            }
            url
          }
        }
      }
      geopoint {
        _type
        alt
        lat
        lng
        _key
      }
      group {
        id
        pageInfo {
          name
          slug {
            current
          }
        }
      }
      phone
      pageInfo {
        name
        slug {
          current
        }
      }
      _rawDescription(resolveReferences: { maxDepth: 20 })
      seo {
        ...SeoFragment
      }
      state
      zip
    }

    settings: sanityLocationSettings {
      _rawReasonsToWorkHere
      applicationLinkManagers
      applicationLinkEmployees
      reasonsToWorkHere {
        _key
        heading
        icon {
          color
          iconImage {
            asset {
              url
            }
            alt
          }
        }
      }
    }

    landingPage: sanityLocationPage {
      pageInfo {
        slug {
          current
        }
      }
    }
  }
`;

const PageTemplate = props => {
  const pageData = props.data.page;
  const { settings } = props.data;
  const { landingPage } = props.data;
  _.omit(props, 'data'); // Remove 'data' since we don't want to send it to the component twice
  const pageProps = props;

  return (
    <LocationPageDefault
      pageProps={pageProps}
      pageData={pageData}
      settings={settings}
      landingPage={landingPage}
    />
  );
  // return <PageDefault pageProps={pageProps} pageData={pageData} />;
};

export default PageTemplate;
