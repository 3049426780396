import React from 'react';
// import tw from 'tailwind.macro';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Carousel, { Modal, ModalGateway } from 'react-images';

import { Container, IconBox } from '@elements';
import { hasImage, lineBreakToBr, evenOrOdd } from '@utils';
import Layout from '@components/Layout';
import Seo from '@components/Seo';
import GoogleMap from '@components/GoogleMap';
import BlockContent from '@components/BlockContent';
import BlockContentSimple from '@components/BlockContentSimple';

const PageDefault = ({ pageProps, pageData, settings, landingPage }) => {
  const seo = pageData && pageData.seo ? pageData.seo : '';

  const imageExists =
    pageData.gallery &&
    pageData.gallery.images &&
    hasImage(pageData.gallery.images[0]);

  // React Image Gallery
  const [modalIsOpen, toggleModal] = React.useState(false);
  const [selectedIndex, changeSelectedIndex] = React.useState(0);

  const toggleLightbox = (selectedIndex) => {
    toggleModal(!modalIsOpen);
    changeSelectedIndex(selectedIndex);
  };

  const images = [];
  let img = {};

  if (imageExists) {
    pageData.gallery.images.map((image) => {
      if (image && image.asset && image.asset.url) {
        img = {
          source: `${image.asset.url}?w=1200`,
          caption: `${image.caption || ''}`,
        };
        images.push(img);
      }
      return null;
    });
  }

  // Prepare map data
  const locations = [
    {
      node: { ...pageData },
    },
  ];

  const center = {
    lat: pageData.geopoint.lat,
    lng: pageData.geopoint.lng,
  };

  console.log('pageData._rawDescription:', pageData._rawDescription);

  return (
    <>
      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={() => toggleModal(!modalIsOpen)}>
            <Carousel views={images} currentIndex={selectedIndex} />
          </Modal>
        ) : null}
      </ModalGateway>
      <Seo context={pageProps.pageContext} {...seo} />
      <Layout>
        <section className="color-mode-background-200">
          <Container>
            <div className="ml-5 sm:ml-8">
              <h1 className="mb-0">
                {pageData.pageInfo &&
                  pageData.pageInfo.name &&
                  pageData.pageInfo.name}
              </h1>
              <p className="text-base sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Located in {pageData.city && pageData.city},{' '}
                {pageData.state && pageData.state}
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
              <div className="col-span-1 sm:rounded-lg">
                <div className="color-mode-background shadow">
                  {pageData?._rawDescription &&
                    pageData?._rawDescription?.length > 0 && (
                      <div className="p-5 sm:p-8">
                        {/* <p className="mt-1 max-w-2xl text-sm leading-5 "> */}
                        {pageData._rawDescription && (
                          <BlockContent
                            blocks={pageData._rawDescription || []}
                            className="mt-1 max-w-2xl text-sm leading-5"
                          />
                        )}
                        {/* </p> */}
                      </div>
                    )}
                  <div className="px-4 py-5 sm:p-0">
                    <dl>
                      {pageData.address && (
                        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:px-6 sm:py-5">
                          <dt className=" leading-5 font-medium ">Address</dt>
                          <dd className="mt-1  leading-5  sm:mt-0 sm:col-span-2">
                            {pageData.address}
                            {(pageData.city ||
                              pageData.state ||
                              pageData.zip) && (
                              <>
                                <br />
                                {pageData.city && `${pageData.city},`}{' '}
                                {pageData.state && `${pageData.state}.`}{' '}
                                {pageData.zip && pageData.zip}
                                {pageData.address &&
                                  pageData.city &&
                                  pageData.state &&
                                  pageData.zip && (
                                    <>
                                      <br />
                                      <a
                                        href={`https://maps.google.com/?q=${pageData.address}, ${pageData.city}, ${pageData.state}, ${pageData.zip}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Go to map
                                      </a>
                                    </>
                                  )}
                                {!(
                                  pageData.address &&
                                  pageData.city &&
                                  pageData.state &&
                                  pageData.zip
                                ) &&
                                  pageData.geopoint.lat &&
                                  pageData.geopoint.lng && (
                                    <>
                                      <br />
                                      <a
                                        href={`https://maps.google.com/?ll=${pageData.geopoint.lat},${pageData.geopoint.lng}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Go to map
                                      </a>
                                    </>
                                  )}
                              </>
                            )}
                          </dd>
                        </div>
                      )}
                      {pageData.phone && (
                        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:px-6 sm:py-5">
                          <dt className=" leading-5 font-medium ">
                            Phone Number
                          </dt>
                          <dd className="mt-1  leading-5  sm:mt-0 sm:col-span-2">
                            <a
                              href={`tel:${pageData.phone.replace(/\D/g, '')}`}
                            >
                              {pageData.phone}
                            </a>
                          </dd>
                        </div>
                      )}
                      {pageData.hours && (
                        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:px-6 sm:py-5">
                          <dt className=" leading-5 font-medium ">
                            Store Hours
                          </dt>
                          <dd className="mt-1  leading-5  sm:mt-0 sm:col-span-2">
                            {lineBreakToBr(pageData.hours)}
                          </dd>
                        </div>
                      )}
                      {pageData.group &&
                        pageData.group.pageInfo &&
                        pageData.group.pageInfo.name && (
                          <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:px-6 sm:py-5">
                            <dt className=" leading-5 font-medium ">Region</dt>
                            <dd className="mt-1  leading-5  sm:mt-0 sm:col-span-2">
                              {pageData.group.pageInfo.slug &&
                                pageData.group.pageInfo.slug.current &&
                                landingPage &&
                                landingPage.pageInfo &&
                                landingPage.pageInfo.slug &&
                                landingPage.pageInfo.slug.current && (
                                  <Link
                                    to={`/${landingPage.pageInfo.slug.current}#${pageData.group.pageInfo.slug.current}`}
                                  >
                                    {pageData.group.pageInfo.name}
                                  </Link>
                                )}
                            </dd>
                          </div>
                        )}
                      {pageData.locationId && (
                        <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:px-6 sm:py-5">
                          <dt className=" leading-5 font-medium ">
                            Location ID #
                          </dt>
                          <dd className="mt-1  leading-5  sm:mt-0 sm:col-span-2">
                            {pageData.locationId}
                          </dd>
                        </div>
                      )}
                    </dl>
                  </div>
                </div>
              </div>

              <div>
                {pageData.geopoint && (
                  <div>
                    <GoogleMap
                      locationsData={locations}
                      zoom={14}
                      center={center}
                    />
                  </div>
                )}
                {imageExists && (
                  <div className="grid grid-cols-3 gap-4 mt-6">
                    {pageData.gallery && (
                      <>
                        {pageData.gallery.images.images}
                        {pageData.gallery.images.map((image, i) => {
                          if (image && image.asset && image.asset.fluid) {
                            return (
                              <Figure
                                onClick={() => toggleLightbox(i)}
                                key={image._key}
                                className={`relative cursor-pointer rounded w-full h-0 ${
                                  i === 0 && 'col-span-3'
                                }`}
                              >
                                <div className="cover opacity-0 absolute top-0 left-0 w-full h-full bg-black pointer-events-none z-10 transition duration-300 ease-in-out" />
                                <Img
                                  fluid={image.asset.fluid}
                                  alt={
                                    image.alt ||
                                    image.caption ||
                                    pageData.address
                                  }
                                  className=""
                                />
                              </Figure>
                            );
                          }
                          return null;
                        })}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Container>
        </section>

        {settings.reasonsToWorkHere && (
          <Container>
            <div className="relative lg:grid lg:grid-cols-3 lg:col-gap-8">
              <div className="lg:col-span-1">
                <h2 className="">Why work at Dunkin'?</h2>
              </div>
              <div className="mt-10 sm:grid sm:grid-cols-2 sm:col-gap-8 sm:row-gap-10 lg:col-span-2 lg:mt-0">
                {settings.reasonsToWorkHere.map((data, i) => (
                  <div
                    key={data._key}
                    className={`mt-10 sm:mt-0 ${
                      evenOrOdd(settings.reasonsToWorkHere.length) === 'odd' &&
                      i === settings.reasonsToWorkHere.length - 1 &&
                      'sm:col-span-2'
                    }`}
                  >
                    {data.icon && data.icon.color && data.icon.iconImage && (
                      <IconBox
                        className=""
                        color={data.icon.color}
                        iconImage={data.icon.iconImage}
                      />
                    )}

                    <div className="mt-5">
                      <h5 className="text-lg leading-6 font-medium ">
                        {data.heading && data.heading}
                      </h5>
                      {settings._rawReasonsToWorkHere &&
                        settings._rawReasonsToWorkHere[i] &&
                        settings._rawReasonsToWorkHere[i].text && (
                          <BlockContentSimple
                            blocks={settings._rawReasonsToWorkHere[i].text}
                          />
                        )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Container>
        )}

        {(settings.applicationLinkManagers ||
          settings.applicationLinkEmployees) && (
          <section className="bg-secondary color-mode-dark">
            <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
              <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
                Submit an application.
              </h2>
              <div className="mt-8 flex justify-center">
                {settings.applicationLinkManagers && (
                  <div className="inline-flex rounded-md shadow">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={settings.applicationLinkManagers}
                      className="btn hover:bg-gray-900 hover:border-gray-900"
                    >
                      Crew Member
                    </a>
                  </div>
                )}
                {settings.applicationLinkEmployees && (
                  <div
                    className={`${
                      settings.applicationLinkManagers && 'ml-3'
                    } inline-flex`}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={settings.applicationLinkEmployees}
                      className="btn btn-ghost hover:border-white hover:bg-white hover:text-gray-900"
                    >
                      Restaurant Manager
                    </a>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
      </Layout>
    </>
  );
};

const Figure = styled.figure`
  padding-top: calc(232 / 333 * 100%);

  &:after {
    content: '';
    width: 90%;
    height: 100%;
    position: absolute;
    box-shadow: 0px 0 0 rgba(0, 0, 0, 0);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    ${(props) => props.theme.transition};
    box-shadow: 0px 0 0 rgba(0, 0, 0, 0);
  }

  img,
  .gatsby-image-wrapper {
    position: absolute !important;
    /* border: 5px solid ${(props) => props.theme.color.white}; */
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    border-radius: 3px;
    margin-bottom: 0;
  }

  &:hover {
    .cover {
      opacity: 0.3 !important;
    }
  }
`;

export default PageDefault;
